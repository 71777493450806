import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import FormWithSender from './FormWithSender/FormWithSender';
import OrderModal from './OrderModal/OrderModal';

axios.defaults.baseURL = 'http://localhost:3001';
// axios.defaults.withCredentials = true;
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>,
// );
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <FormWithSender />
  </BrowserRouter>,
);

// const root2 = ReactDOM.createRoot(document.getElementById('root2'));
// root2.render(
//   <BrowserRouter>
//     <div><OrderModal /></div>
//   </BrowserRouter>,
// );

// const root3 = ReactDOM.createRoot(document.getElementById('root3'));
// root3.render(
//   <BrowserRouter>
//     <div><OrderModal /></div>
//   </BrowserRouter>,
// );

// const root4 = ReactDOM.createRoot(document.getElementById('root4'));
// root4.render(
//   <BrowserRouter>
//     <div><OrderModal /></div>
//   </BrowserRouter>,
// );
