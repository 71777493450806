import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: 250, md: 400 },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AfterOrderModal({
  // handleOpenOrderModal,
  handleCloseOrderModal,
  openMessage,
}) {
  return (
    <div>
      <Modal
        open={openMessage}
        onClose={handleCloseOrderModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: 'center' }}
          >
            Спасибо за ваш заказ!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Мы получили ваше сообщение.
            <br />
            Наш менеджер
            свяжется с вами в ближайшее время, чтобы уточнить
            детали
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
