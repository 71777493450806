import * as React from 'react';
import { useState, useRef } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import InputMask from 'react-input-mask';

import emailjs from '@emailjs/browser';
// import { useMask } from 'react-mask-field';
import AfterOrderModal from '../AfterOrderModal/AfterOrderModal';
// import PhoneMask from '../PhoneMask/PhoneMask';

export default function FormWithSender() {
  // данные бота MiraGrillBot и чата MiraGrillOrders для отправки в телегу
  const botToken = '6296545496:AAHVoTaVuam_Y9DwWefv-1FfXDRcOLBZRus';
  const chatId = '-1001801746808';

  // состояние окон формы
  const [input, setInput] = useState({
    form: 'Заказ стола',
    clientname: '',
    phone: '',
    personsQuality: '1',
    datetime: '',
  });

  // ручка чтобы менять значение формы
  const changeInputHandler = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // константа для заказа
  const order = {
    form: input.form,
    name: input.clientname,
    phone: input.phone,
    guests: input.personsQuality,
    datetime: input.datetime,
  };

  const orderTelegram = `У вас новый заказ! Имя: ${input.clientname} Телефон: ${input.phone} Количество персон: ${input.personsQuality} Когда придут: ${input.datetime} `;

  // ручка отправки и сброса формы
  const handleSendMessage = () => {
    axios.post(`https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&parse_mode=html&text=${JSON.stringify(orderTelegram)}`, orderTelegram);
    // .then((res) => console.log(res.data, '===это res==='));

    // отправка на мыло
    emailjs.send('service_tqau08j', 'template_8x0hgzz', order, 'wN156RBAwtj6HfdQ5')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });

    // console.log(order);

    setInput({
      form: 'Заказ стола',
      clientname: '',
      phone: '',
      personsQuality: '1',
      datetime: '',
    });
  };

  // состояние и ручка для модалки после отправки заказа
  const [openMessage, setOpenMessage] = React.useState(false);
  const handleOpenOrderModal = () => {
    setOpenMessage(true);
    setTimeout(() => { setOpenMessage(false); }, 3500);
  };
  const handleCloseOrderModal = () => setOpenMessage(false);

  return (
    <Box
      className="forma"
      sx={{
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: { xs: 'center', sm: 'center', md: 'flex-start' },
      }}
    >
      <h1 style={{ fontSize: '40px' }}>
        Забронировать стол в караоке
      </h1>
      <h3 style={{
        marginTop: '20px',
        fontWeight: '300',
        fontSize: '20px',
      }}
      >
        Заполните пожалуйста форму чтобы мы
        знали когда вы придете и встретили вас
      </h3>
      <TextField
        onChange={changeInputHandler}
        value={input.clientname}
        fullWidth
        name="clientname"
        label="Как вас зовут?"
        id="fullWidth"
        sx={{ marginBottom: '20px', marginTop: '25px' }}
      />

      <InputMask
        mask="+7 (999) 999 99 99"
        disabled={false}
        maskChar=" "
        value={input.phone}
        onChange={changeInputHandler}
      >
        {() => (
          <TextField
            fullWidth
            name="phone"
            type="tel"
            label="Ваш телефон"
            id="fullWidth"
            sx={{ marginBottom: '20px' }}
          />
        )}
      </InputMask>

      <div style={{ marginBottom: '10px', fontWeight: '300' }}>
        Сколько гостей?
      </div>
      <TextField
        onChange={changeInputHandler}
        value={input.personsQuality}
        type="number"
        name="personsQuality"
        label=""
        id="fullWidth"
        sx={{ width: '216px', marginBottom: '20px' }}
      />

      <div style={{ marginBottom: '10px', fontWeight: '300' }}>
        Когда и во сколько вы придете?
      </div>
      <TextField
        onChange={changeInputHandler}
        value={input.datetime}
        type="datetime-local"
        name="datetime"
        label=""
        id="fullWidth"
        sx={{ width: '216px', marginBottom: '20px' }}
      />

      <Button
        sx={{
          mt: 2,
          width: '230px',
          height: '55px',
          background: '#711c42',
          borderRadius: '30px',
        }}
        onClick={() => {
          handleSendMessage();
          // handleClose();
          handleOpenOrderModal();
        }}
        variant="contained"
        size="large"
        endIcon={<SendIcon />}
      >
        ОТПРАВИТЬ
      </Button>
      <AfterOrderModal
        handleOpenOrderModal={handleOpenOrderModal}
        openMessage={openMessage}
      />

    </Box>
  );
}
